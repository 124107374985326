<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Vanadium hexacarbonyl,
        <chemical-latex content="V(CO)6," />
        reacts with trimethylphosphite,
        <chemical-latex content="P(OCH3)3," />
        according to
      </p>

      <p class="pl-12 mb-5">
        <stemble-latex content="$\text{rate}=-\text{k}\ce{[V(CO)6][P(OCH3)3]}$" />
        <stemble-latex
          content="$\text{where k}=0.700\,\text{M}^{-1}\text{s}^{-1}\text{ at 25}\,^\circ\text{C}$"
        />
      </p>

      <p class="mb-2">
        If
        <number-value :value="molTMP" unit="\text{mol}" />
        of trimethylphosphite is added to
        <stemble-latex content="$1.000\,\text{L}$" />
        of a
        <number-value :value="concVCO6" unit="\text{M}" />
        solution of vanadium hexacarbonyl, what is the concentration of
        <chemical-latex content="V(CO)6" />
        after
        <number-value :value="time" unit="\text{s?}" />
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\ce{[V(CO)6]:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemSIU131_Task17',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    molTMP() {
      return this.taskState.getValueBySymbol('molTMP').content;
    },
    time() {
      return this.taskState.getValueBySymbol('time').content;
    },
    concVCO6() {
      return this.taskState.getValueBySymbol('molTMP').content;
    },
  },
};
</script>
